import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "WEB 面接の裏側",
  "date": "2020-11-26T10:01:03.000Z",
  "slug": "entry/2020/11/26/190103",
  "tags": ["medley"],
  "hero": "./2020_11_26.png",
  "heroAlt": "Web 面接"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`株式会社メドレーのエンジニアの笹塚です。 私が開発を担当しているジョブメドレーで、先月 10 月 23 日に WEB 面接・動画選考をリリースしました。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="WEB 面接・動画選考機能のリリースのお知らせ | ジョブメドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fjob-medley.com%2Frelease%2F70%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://job-medley.com/release/70/">job-medley.com</a></cite>  WEB 面接、動画選考ともに、昨今の非対面での就職活動ニーズに応えるべく開発しました。
    <p>{`リリースは 2 つの機能を同時ですが、今回は WEB 面接の裏側に絞ってご紹介します。`}</p>
    <h1>{`WEB 面接概要`}</h1>
    <p>{`WEB 面接とは、リアルタイムで事業者様と求職者様が、オンライン面接を行うことができる機能です。 専用のアプリケーションは必要なく、PC、スマートフォンのブラウザから利用できます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126143843.png",
      "alt": "20201126143843.png"
    }}></img>
    <h1>{`サービス選定`}</h1>
    <p>{`開発にあたり、いくつかの候補があがりましたが、最終的には自社内でも導入実績のある SkyWay を使用することにしました。`}</p>
    <h1>{`SkyWay とは`}</h1>
    <p>{`WebRTC（Web Real Time Communication）を使用したオンラインのビデオ通話を、サービスに導入できるマルチプラットフォーム SDK です。`}</p>
    <p>{`2020 年 11 月時点で、JavaScript SDK、iOS SDK、Android SDK が提供されています。`}</p>
    <ul>
      <li parentName="ul">{`シグナリングサーバなどの WebRTC に必要となるインフラ構築が不要です`}</li>
      <li parentName="ul">{`使用上限つきの無料プランもあります`}</li>
      <li parentName="ul">{`NTT コミュニケーションズが開発しています`}</li>
    </ul>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="SkyWay | アプリや Web サービスに、ビデオ・音声通話をかんたんに導入・実装できる SDK" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwebrtc.ecl.ntt.com%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://webrtc.ecl.ntt.com/">webrtc.ecl.ntt.com</a></cite>
    <p>{`WEB 面接の対応ブラウザバージョン（2020 年 11 月時点）`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`プラットフォーム`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`対応バージョン`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PC: Google Chrome`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`バージョン 84 以上`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PC: Microsoft Edge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`バージョン 84 以上`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iOS: Safari`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iOS12 以上`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Android: Google Chrome`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`バージョン 85 以上 Andoid9 以上`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`SkyWay`}{` `}{` JavaScript SDK の動作確認ブラウザ、WebRTC の対応状況、利用者の利用傾向から対応ブラウザのバージョンを上記のように設定しました。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Javascript SDK | ドキュメント | SkyWay(アプリや Web サービスに、ビデオ・音声通話をかんたんに導入・実装できる SDK)" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwebrtc.ecl.ntt.com%2Fdocuments%2Fjavascript-sdk.html%23javascript-sdk" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://webrtc.ecl.ntt.com/documents/javascript-sdk.html#javascript-sdk">webrtc.ecl.ntt.com</a></cite>
    <h1>{`SkyWay の接続モデル`}</h1>
    <p>{`SkyWay でビデオ通話を実装する場合、2 種類の接続モデルから選びます。`}</p>
    <h2>{`SkyWay 電話モデル`}</h2>
    <ul>
      <li parentName="ul">{`電話のように 1 対 1 でのビデオ通話を想定したモデルです。`}</li>
      <li parentName="ul">{`Peer インスタンス(シグナリングサーバによって発行された一意の PeerID を持つ)同士で接続します。`}</li>
      <li parentName="ul">{`接続するためには、相手の PeerID が必要になります。`}</li>
    </ul>
    <h2>{`SkyWay ルームモデル`}</h2>
    <ul>
      <li parentName="ul">{`同一ルーム内の全ての Peer でビデオ通話するモデルです。`}</li>
      <li parentName="ul">{`ルーム名を使用して参加します。相手の PeerID を知る必要はありません。`}</li>
      <li parentName="ul">{`ルーム名は API キー毎に独立しています。`}</li>
      <li parentName="ul">{`ルームの接続タイプはフルメッシュか SFU の 2 種類から選べます。`}</li>
      <li parentName="ul">{`参加者全員へのチャットなどのデータ送信もできます。`}</li>
    </ul>
    <p>{`ジョブメドレーの WEB 面接では、 今後の機能拡張を想定して、こちらのルームモデルを採用しました。`}</p>
    <h1>{`ルームの通信タイプ`}</h1>
    <p>{`ルームに複数人が参加している場合、それぞれの通信をどう行うかを、メッシュと SFU から選ぶことができます。`}</p>
    <h2>{`フルメッシュ`}</h2>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "画像引用元: https://webrtc.ecl.ntt.com/skyway/overview.html#_4-sfu%E3%82%B5%E3%83%BC%E3%83%90"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126180010.png",
        "alt": "20201126180010.png"
      }}></img>
      <figcaption parentName="figure" {...{
        "className": "mceEditable"
      }}>{`画像引用元: https://webrtc.ecl.ntt.com/skyway/overview.html#_4-sfu%E3%82%B5%E3%83%BC%E3%83%90`}</figcaption>
    </figure>
    <p>{`全員が相互に通信を行います。人数が増えると、人数分端末のエンコード負荷と通信量が増加します。`}</p>
    <h2>{`SFU`}</h2>
    <p>{`SFU の場合、上りの接続は 1 本になるので、メッシュよりも端末のエンコード負荷や、通信量の軽減が期待できます。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "画像引用元: https://webrtc.ecl.ntt.com/skyway/overview.html#_4-sfu%E3%82%B5%E3%83%BC%E3%83%90"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126180029.png",
        "alt": "20201126180029.png"
      }}></img>
      <figcaption parentName="figure" {...{
        "className": "mceEditable"
      }}>{`画像引用元: https://webrtc.ecl.ntt.com/skyway/overview.html#_4-sfu%E3%82%B5%E3%83%BC%E3%83%90`}</figcaption>
    </figure>
    <p>{`通信方式の違いは SkyWay が隠蔽してくれるので、joinRoom 時の mode を mesh から sfu に変更するだけで切り替わります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`peer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`joinRoom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`roomName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`mode`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mesh か sfu を指定"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`stream`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` mediaStream `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="概要 | SkyWay(アプリや Web サービスに、ビデオ・音声通話をかんたんに導入・実装できる SDK)" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwebrtc.ecl.ntt.com%2Fskyway%2Foverview.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://webrtc.ecl.ntt.com/skyway/overview.html">webrtc.ecl.ntt.com</a></cite>
    <p>{`ジョブメドレーの WEB 面接では、面接参加人数を考慮して mesh を使用しています。`}</p>
    <h1>{`実装イメージ`}</h1>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` peer `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Peer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`peer_id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` api_key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

peer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`once`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"open"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  room`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`once`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"open"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ルーム参加後に発生するイベント`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  room`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"peerJoin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token literal-property property"
            }}>{`peerId`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ルームに誰か参加した場合に発生するイベント`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  room`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"stream"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token literal-property property"
            }}>{`stream`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` RoomStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// stream を受けた場合に発生するイベント`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  room`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"data"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` src`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` data `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// data を受けた場合に発生するイベント`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

peer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"error"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token literal-property property"
            }}>{`error`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// エラー発生時に発生するイベント`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

peer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`joinRoom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`roomName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`mode`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mesh"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`stream`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` mediaStream `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

room`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

peer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`disconnect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Peer を作成し`}</p>
    <ol>
      <li parentName="ol">{`peer.joinRoom() でルームに参加`}</li>
      <li parentName="ol">{`room.stream イベントで他の参加者の stream を受け取る`}</li>
      <li parentName="ol">{`room.data() でチャットなど、データ送信もできる`}</li>
      <li parentName="ol">{`room.close() でルームから退出 が SkyWay の JavaScript SDK を使用した基本的な実装になります。`}</li>
    </ol>
    <p>{`この実装に`}</p>
    <ul>
      <li parentName="ul">{`navigator.mediaDevices.getUserMedia() で取得した stream を joinRoom で渡す`}</li>
      <li parentName="ul">{`steam イベントで受け取った stream を video で再生する`}</li>
    </ul>
    <p>{`を追加すれば、オンラインでのビデオ通話が可能になります。`}</p>
    <h1>{`スマートフォン対応`}</h1>
    <p>{`PC と同様のコードでほぼ動作しましたが、iOS や Android 端末で、機種依存と思われる挙動の調査と対応に時間がかかりました。その一部を紹介します。`}</p>
    <h2>{`タブを移動すると映像が映らない`}</h2>
    <p>{`発生した問題 iOS12、iOS13 などで`}</p>
    <ul>
      <li parentName="ul">{`複数のタブをひらいた場合に、映像の取得ができなくなる`}</li>
      <li parentName="ul">{`スクリーンロックからの復帰時に、映像が取得できなくなる`}</li>
    </ul>
    <p>{`という問題が起きました。iOS14 ではタブ切り替え時に映像を取得できるようになっていましたが、スクリーンロックからの復帰時は映像を取得できないままでした。`}</p>
    <h3>{`対応`}</h3>
    <p>{`この対応は visibilitychange イベントで、タブの切り替えと、スクリーンロックからの復帰時のイベントを拾い`}</p>
    <ol>
      <li parentName="ol">{`取得済の stream の track を stop する`}</li>
      <li parentName="ol">{`stream を取り直す`}</li>
      <li parentName="ol">{`SkyWay の room.replaceStream() で、WebRTC で使用している stream を差し替える`}</li>
    </ol>
    <p>{`以上の実装により、対応しました。 `}{`_`}{`_`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"visibilitychange"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`visibilityState `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!==`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"visible"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  localMedia`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTracks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token literal-property property"
            }}>{`track`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` MediaStreamTrack`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    track`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`stop`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` replaceStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mediaDevices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getUserMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`video`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`audio`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  room`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`replaceStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`replaceStream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2>{`イヤホンの操作で映像が止まる`}</h2>
    <h3>{`発生した問題`}</h3>
    <p>{`iOS12、iOS13 では起こりませんでしたが、iOS14 でオンライン面接途中にイヤホンをスマートフォンから外すと、相手側の映像が止まるようになりました。`}</p>
    <h3>{`対応`}</h3>
    <p>{`それまでは、相手の映像と音声を再生するために、video タグに stream を渡して映像と音声を再生していましたが`}</p>
    <ul>
      <li parentName="ul">{`video : mute にして映像を再生`}</li>
      <li parentName="ul">{`audio : 音声を再生`}</li>
    </ul>
    <p>{`と、音声と再生を分けたところ、イヤホンを外しても停止することはなくなりました。`}</p>
    <h2>{`制約設定`}</h2>
    <p>{`getUserMedia で取得する MediaStream は、制約を設定することでデバイスの消費リソースを抑えることができます。`}</p>
    <h3>{`設定例:`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mediaDevices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getUserMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`audio`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`video`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`↓`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mediaDevices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getUserMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`audio`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`video`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`frameRate`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`制約が設定可能かどうかの確認:`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`getSupportedConstraints()`}</code>{` で、対応している制約名を取得することができます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` supportedConstraints `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mediaDevices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getSupportedConstraints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`使用しているブラウザがその制約に対応しているかを確認し、対応している場合のみ設定を有効にします。`}</p>
    <p>{`例えば、スマートフォンの場合に以下の設定をすれば、インカメラを使用し、フレームレートを 20 に抑え、320x320 の解像度に制限することができます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` options `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`facingMode`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"user"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`frameRate`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`320`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`320`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`指定した制限が必ず使用される保証はなく、機種依存の影響を受ける設定でもあるので、対象としている環境にあわせて検証と調整をする必要がある点には注意が必要です。`}</p>
    <p>{`動作検証中に、機種依存と思われる挙動をした例を紹介します。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`オプション`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`挙動`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`frameRate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`指定すると一部 Android 端末で以下の挙動をした。`}<br />{` 1. Android 端末で面接に参加する`}<br />{` 2. iOS Safari で参加する`}<br />{` 3. Android 側の映像と音声が Safari に送られない Safari で先に参加する場合には問題がない。`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`解像度指定`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`例: width: 320, height: 320 frameRate だけ指定していたときに上記の挙動をした Android、iOS Safari の組み合わせで問題が起こらなくなった。`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`解像度を指定するとインカメラではなく、リアカメラを使う Android 端末があった。`}</p>
    <p>{`制約設定については、現在も調整中です。`}</p>
    <h1>{`参考情報`}</h1>
    <h2>{`SkyWay Conference`}</h2>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="SkyWay Conference" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fconf.webrtc.ecl.ntt.com%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://conf.webrtc.ecl.ntt.com/">conf.webrtc.ecl.ntt.com</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="skyway/skyway-conf" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fgithub.com%2Fskyway%2Fskyway-conf" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://github.com/skyway/skyway-conf">github.com</a></cite>
    <p>{`SkyWay のルーム機能を使用したデモ環境です。 GitHub にコードも公開されているので、ルーム を使用した場合の挙動と実装方法を確認できます。 開発をしていると、実装の問題なのか、SkyWay の SDK の仕様なのか、特定のデバイスで起こる問題なのかの切り分けに時間がかかるため、こちらの環境が参考になりました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`SkyWay が WebRTC とグループでのビデオ通話の実装を統合して提供してくれるため、開発時は、自社サービスとしての WEB 面接の機能に集中することができました。`}</p>
    <p>{`スマートフォンのブラウザ対応と調査に時間がかかることもありますが、今後も利用者からのフィードバックを得ながら改善していきたいと思います。`}</p>
    <p>{`メドレーでは、ニーズにあわせた新機能の開発にも力を入れています。多くの利用者に実際に使われるサービスの開発をしてみたいと思った方、ぜひお気軽にお話しましょう！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集の一覧 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      